import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { createJSONStorage, devtools, persist, subscribeWithSelector } from 'zustand/middleware'
import { ApolloClient, ApolloError } from '@apollo/client';
import { Site, TUser } from '../../generated/gql/graphql';
import { CURRENT_USER, GET_SITES, GET_SITE_IDS } from '../graphql/query';

export interface IdentityState {
  user: TUser | null,
  workspaceId: string | null,
  workspaces: Site[],
}

const defaultIdentity: IdentityState = {
  user: null,
  workspaceId: null,
  workspaces: [],
}

export interface IdentityLoader {
  loading: boolean,
  error: ApolloError | undefined,
  loadIdentity(client: ApolloClient<object>, loadWorkspaces?: boolean): Promise<void>,
}

export const useIdentityStore = create<IdentityState & IdentityLoader>()(
  devtools(persist(subscribeWithSelector((set, get) => ({
    loadIdentity: async (client: ApolloClient<object>, loadWorkspaces: boolean) => {
      set({ loading: true });
      const { data, error } = await client.query({
        query: CURRENT_USER,
        fetchPolicy: 'no-cache',
      });
      if (error) {
        set({ error, loading: false });
        return;
      }
      set({ user: data.user || null });
      if (data.user && loadWorkspaces) {
        const { data, error } = await client.query({
          query: GET_SITES,
          fetchPolicy: 'no-cache',
        });
        if (error) {
          set({ error, loading: false });
          return;
        }
        const currentWorkspaceId = get().workspaceId;
        const workspaceId = data.sites.find(s => s.id === currentWorkspaceId) ? currentWorkspaceId : data.sites[0].id || null;
        set({ workspaces: data.sites, workspaceId });
      }
      else {
        set({ workspaces: [], workspaceId: null });
      }
      set({ loading: false });
    },
    loading: false,
    error: undefined,
    ...defaultIdentity,
  })),
    {
      name: 'go-pixie-identity-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ))
)
