import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react'
import { Transforms, createEditor, Descendant, BaseText, Element, Editor, Node, Path, Text, Range } from 'slate'
import {
  Slate,
  Editable,
  withReact,
  useSelected,
  useFocused,
  RenderElementProps,
  ReactEditor,
} from 'slate-react'
import { DataRefPopoverProps, withDataRefPopover } from './DataRefPopover';
import { DynamicValueDisplay } from './DynamicValueDisplay';
import { DynamicValue, FormattedDynamicValue, isSimple, toExpression } from '../../types/DynamicValueTypes';
import { isEqual } from 'lodash';
import { Typography, useTheme } from '@mui/material';
import { isNotNull } from '../../utils/common';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { logDebug } from '../../utils/logging';
import { useEditorStore } from '../../hooks/EditorState';


const EditableWithDataRef = withDataRefPopover(Editable);


const withSoftBreaks = (editor: ReactEditor) => {
  editor.insertBreak = () => {
    Transforms.insertText(editor, '\n');
  };

  return editor;
};

function toDescendant(dvOrString: string | DynamicValue): Descendant {
  if (typeof dvOrString == 'string') return { text: dvOrString };
  return {
    type: 'dynamicValue',
    dynamicValue: dvOrString,
    children: [{ text: '' }],
  }
}

function fromDescendant(descendant: Descendant): string | DynamicValue | null {
  if (Element.isElementType<DynamicValueElement>(descendant, "dynamicValue")) {
    return descendant.dynamicValue;
  }
  else if (Text.isText(descendant)) return descendant.text;
  return null;
}

function dynamicValueToSlateValue(dynamicValue: DynamicValue | FormattedDynamicValue | null): Descendant[] {
  if (isSimple(dynamicValue)) return dynamicValueToSlateValue([dynamicValue]);

  // slate expect the initial value to be a list non-leaf, non-void element (as document roots)
  const ret: ExpressionValueElement[] = [
    {
      type: 'expression',
      // slate expect children to always start with a empty text node instead of empty children list
      // so it's valid to insert stuff. Otherwise insert fails
      children: [{ text: '' }],
    }
  ];
  if (dynamicValue) {
    ret[0].children = [
      ...ret[0].children,
      ...dynamicValue.map(toDescendant),
      { text: '' },
    ]
  }
  return ret;
}

function slateValueToDynamicValue(slateValue: Descendant[]): DynamicValue | FormattedDynamicValue | null {
  const exps = slateValue as ExpressionValueElement[];
  const elems = exps.flatMap(
    exp => exp.children
      .map(fromDescendant)
      .filter(isNotNull<DynamicValue | string>)
      .filter(item => item)
  ); //filter out empty string
  logDebug('converting slate value to dynamic value')
  logDebug(slateValue);
  logDebug(elems);
  if (elems.length == 0) return null;
  if (elems.length == 1 && typeof elems[0] != 'string') return elems[0];
  return elems;
}

export const resetNodes = (
  editor: Editor,
  nodes?: Node[],
): void => {
  const children = [...editor.children];
  // skip reset if the nodes are the same
  if (isEqual(children, nodes)) return;
  for (let i = 0; i < children.length; i++) {
    const node = children[i];
    editor.apply({ type: 'remove_node', path: [0], node });
  }

  if (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      editor.apply({ type: 'insert_node', path: [i], node: nodes[i] });
    }
  }
  const endOfDoc = editor.end(editor.last([])[1]);
  Transforms.select(editor, endOfDoc);
};

function restrictMultiElementSelection(editor: ReactEditor) {
  const { selection } = editor;
  if (selection && Range.isExpanded(selection)) {
    const [start, end] = Range.edges(selection);
    const startBlock = Editor.node(editor, start.path);
    const endBlock = Editor.node(editor, end.path);

    if (startBlock[1].toString() !== endBlock[1].toString()) {
      // If start block and end block are not the same, adjust the selection
      // For example, collapsing the selection to the start of the range
      Transforms.collapse(editor, { edge: 'start' });
    }
  }
};

function getDynamicValueNode(editor: ReactEditor, range: Range | null): { element: DynamicValueElement | null, path: Path | null } {
  if (!range || !Range.isRange(range)) {
    return { element: null, path: null };
  }

  const nodeGen = Editor.nodes(editor, {
    at: range,
    match: n => Element.isElementType(n, 'dynamicValue'),
  });

  for (const nodeEntry of nodeGen) {
    const [n, p] = nodeEntry;
    if (Element.isElementType<DynamicValueElement>(n, 'dynamicValue')) {
      return { element: n, path: p };
    }
  }
  return { element: null, path: null };
}

export const MixedParamInputField = (props: Omit<DataRefPopoverProps, "dynamicValue" | "onChange"> & {
  dynamicValue: DynamicValue | FormattedDynamicValue | null,
  onChange: (dv: DynamicValue | FormattedDynamicValue | null) => void,
}) => {
  const theme = useTheme();

  const editor = useMemo(() => withDynamicValue(withSoftBreaks(withReact(createEditor()))), [])

  // NOTE the initialValue is only used once on initial render by slate
  // so useMemo with no deps here to avoid unnecessary rerender
  const initialValue = useMemo(() => dynamicValueToSlateValue(props.dynamicValue), []);
  const [selection, setSelection] = useState<Range | null>(null);
  const { element: selectedDvElem } = useMemo(
    () => getDynamicValueNode(editor, selection),
    [editor, selection]
  )

  // slate is uncontrolled, we need to manually reset slate with the latest dv when selected node/edge change
  const selectedGraphElem = useEditorStore(state => state.app.graph.selected);
  const dvRef = useRef(props.dynamicValue);

  useEffect(() => {
    dvRef.current = props.dynamicValue;
  }, [props.dynamicValue])

  useEffect(() => {
    resetNodes(editor, dynamicValueToSlateValue(dvRef.current));
  }, [selectedGraphElem]);

  const renderElement = useCallback((renderProps: RenderElementProps) => {
    const { attributes, children, element } = renderProps
    switch (element.type) {
      case 'dynamicValue':
        return <DynamicValueElementRender
          element={element}
          children={children}
          attributes={attributes}
        />
      default:
        return <span {...attributes}>{children}</span>
    }
  }, []);

  // update the dynamic value at given location (insert if text, replace if dynamicValueElem)
  // then trigger onChange callback with the updated value
  const insertAndSelectDynamicValue = (newValue: DynamicValue) => {
    const newNode: DynamicValueElement = {
      type: 'dynamicValue',
      dynamicValue: newValue,
      children: [{ text: '' }],
    };

    // Remove the current selection and insert the new element
    Transforms.delete(editor);
    Transforms.insertNodes(editor, newNode);

    // Select the new element
    const path = Editor.above(editor, {
      match: n => Element.isElement(n),
      at: editor.selection?.anchor
    })?.[1];

    if (path) {
      const startPoint = Editor.start(editor, path);
      const endPoint = Editor.end(editor, path);

      Transforms.select(editor, {
        anchor: startPoint,
        focus: endPoint
      });
    }
    props.onChange(slateValueToDynamicValue(editor.children));
  }

  const refocusEditor = useCallback(() => {
    // focus reset the selection https://github.com/ianstormtaylor/slate/issues/3412
    // TODO look into more elegant solution than this
    const sel = structuredClone(editor.selection);
    ReactEditor.focus(editor);
    setTimeout(() => {
      if (sel) {
        Transforms.setSelection(editor, sel)
      }
      else {
        const endOfDoc = editor.end(editor.last([])[1]);
        Transforms.select(editor, endOfDoc);
      }
    }, 100);

  }, [editor]);

  return <Slate
    editor={editor}
    initialValue={initialValue}
    onValueChange={val => {
      console.log("slate value", val)
      const newVal = slateValueToDynamicValue(val);
      console.log("dv", newVal);
      props.onChange(newVal);
    }}
    onSelectionChange={() => restrictMultiElementSelection(editor)}
    onChange={() => {
      setSelection(editor.selection)
    }}
  >
    <EditableWithDataRef
      renderElement={renderElement}
      //readOnly  cannot use readonly because that breaks the popover focus management
      placeholder="Choose data reference..."
      dataRefProps={{
        ...props,
        dynamicValue: selectedDvElem?.dynamicValue || null,
        onChange(dv: DynamicValue) {
          insertAndSelectDynamicValue(dv);
          refocusEditor();
        },
        setInnerFocus: refocusEditor,
      }}
      style={{
        padding: theme.spacing(1.5),
        border: `1px solid ${theme.palette.text.disabled}`,
        borderRadius: theme.shape.borderRadius,
        outlineColor: theme.palette.primary.main,
        ...theme.typography.body1,
      }}
    />
  </Slate>
}

export type ExpressionValueElement = {
  type: 'expression'
  children: Descendant[],
}

export type DynamicValueElement = {
  type: 'dynamicValue',
  dynamicValue: DynamicValue,
  children: BaseText[],
}


declare module 'slate' {
  interface CustomTypes {
    Editor: ReactEditor
    Element: DynamicValueElement | ExpressionValueElement
  }
}

const withDynamicValue = (editor: Editor): Editor => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = element => {
    return element.type === 'dynamicValue' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'dynamicValue' ? true : isVoid(element)
  }

  editor.markableVoid = element => {
    return element.type === 'dynamicValue' || markableVoid(element)
  }

  return editor
}


const DynamicValueElementRender = (props: {
  attributes,
  element: DynamicValueElement,
  children: BaseText[]
}) => {
  // const nodeIds = useEditorStore(state => Object.keys(state.app.graph.nodesData));

  const selected = useSelected()
  const focused = useFocused()
  const style: React.CSSProperties = {
    padding: '3px 3px 2px',
    margin: '2 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: '#eee',
    fontSize: '0.9em',
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  }

  return (
    <span
      {...props.attributes}
      contentEditable={false}
      style={style}
    >
      {/* {nodeIds.includes(props.element.dynamicValue.reference) */}
      <DynamicValueDisplay dynamicValue={props.element.dynamicValue} />
      {/* //  <Typography component='span' display='inline-flex' alignItems='center' color='error'>
        //   <ErrorOutlineIcon fontSize='small' />
        //   {toExpression(props.element.dynamicValue)}
        // </Typography> */}
      {props.children}
    </span>
  )
}
