import { ApolloError, FetchResult, Observer, useApolloClient } from '@apollo/client';
import { Alert, Box, Button, CircularProgress, GlobalStyles, IconButton, Stack, Typography, alpha, keyframes, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CONTINUE_DEBUG_RUN, CONTINUE_RUN, START_DEBUG_RUN, START_RUN } from '../../../../graphql/subscription';
import { IFlowConfig, IUserInput, RoleV2, TAppDebugInfo, TAppDebugRunResult, TAppDisplayUpdate, TAppRunResult, TAppStatusUpdate, TFlowRunMetadata, TMessage, TStatusMessage, TUserInputRequirement } from '../../../../../generated/gql/graphql';
import { AxiosError } from 'axios';
import { GraphQLError } from 'graphql';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Pulse } from '../../../../components/animations';
import { ReactFlowProvider } from 'reactflow';
import DebugGraphView from './Debug';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChatBubble from './ChatDIalog';
import ChatInput from './ChatInput';
import { useEditorStore } from '../../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { createEdges, createFlowFromZustand, createNodes } from '../../../../utils/graph-conversion';
import { SignInDialog } from '../../../../components/Auth';
import { useClientStore } from '../../../../hooks/ClientState';
import { makeWebPageInteraction } from '../../../../utils/webPageInteraction';
import Markdown from '../../../../components/material-markdown';
import CustomizationProvider from '../../../../components/customization/customization-provider';
import { useIdentityStore } from '../../../../hooks/IdentityState';


export type AppRunOptions = {
  debug: false,
  flowId: string,
  startId: string | null,
} | {
  debug: true,
  flowConfig: IFlowConfig,
  flowId?: string,
  startId: string | null,
  aiConfig?: any,
}

type AppRunError = AxiosError<any, any> | ApolloError | GraphQLError;

type AppRunHook = {
  clientId: string | undefined,
  initializing: boolean,
  inProgress: boolean,
  inputRequirement: TUserInputRequirement | undefined,
  messages: (TMessage | TStatusMessage)[],
  debugLogs: TAppDebugInfo[],
  send: (userInputs: IUserInput[], onComplete?: () => void) => void,
  error: AppRunError | undefined,
  completed: boolean,
  disconnect: (keepSession?: boolean) => void,
}

type Subscription = {
  unsubscribe: () => void,
  closed: boolean,
}

export function useAppRun(
  options: AppRunOptions,
  onSigninRequired: () => void,
  skip?: boolean,
): AppRunHook {
  const client = useApolloClient();
  const fetchPolicy = 'no-cache';

  const siteId = useIdentityStore(state => state.workspaceId);
  const [
    clientId,
    initializing,
    serverInProgress,
    inputRequirement,
    messages,
    debugLogs,
    error,
    completed,
    setClientId,
    increaseUnreadCount,
    setInitializing,
    setServerInProgress,
    setInputRequirement,
    setError,
    setCompleted,
    addDebugLog,
    addMessage,
    resetClient,
  ] = useClientStore(useShallow(state => [
    state.clientId,
    state.initializing,
    state.serverInProgress,
    state.inputRequirement,
    state.messages,
    state.debugLogs,
    state.error,
    state.completed,
    state.setClientId,
    state.increaseUnreadCount,
    state.setInitializing,
    state.setServerInProgress,
    state.setInputRequirement,
    state.setError,
    state.setCompleted,
    state.addDebugLog,
    state.addMessage,
    state.resetClient,
  ]));
  // to properly set the state to a function, it need to be a function that returns the state
  // otherwise setState would just call the function and save the result
  const [disconnect, setDisconnect] = useState<(keepSession?: boolean) => void>(() => () => { });

  const queue = useRef<(
    TUserInputRequirement
    | TMessage
    | TAppDisplayUpdate
    | TAppDebugInfo
    | TAppStatusUpdate
    | TFlowRunMetadata
  )[]>([]);

  // using ref to accurately track if the queue is processing, to avoid duplicate processing
  const queueProcessingInProgress = useRef(false);
  // this is the same as queueProcessingInProgress, but can be outdated. Used for UX rendering
  const [clientInProgress, setClientInProgress] = useState(false);

  const inProgress = serverInProgress || clientInProgress;

  async function processQueue() {
    if (queueProcessingInProgress.current) return;
    queueProcessingInProgress.current = true;
    // NOTE: the queue object is the version when processQueue is initially called
    // here we are not getting the latest queue
    while (queue.current.length > 0) {
      const result = queue.current.shift();

      if (result.__typename == 'TFlowRunMetadata') {
        setClientId(result.clientId);
        setInitializing(false);
      }
      if (result.__typename == 'TMessage') {
        // clear error when there's a new message
        setError(undefined);
        addMessage(result);
        increaseUnreadCount();
      }
      if (result.__typename == 'TAppDisplayUpdate') {
        // clear error when there's a new display update
        setError(undefined);
        if (result.statusMessage) {
          addMessage(result.statusMessage);
        }
        if (result.navigationUrl) {
          addStatusMessage(`Navigate to ${result.navigationUrl}`);
          window.location.href = result.navigationUrl;
        }
        if (result.interaction) {
          const actionInputStr = result.interaction.actionInput ? ` with input \`${result.interaction.actionInput}\`` : ''
          addStatusMessage(`${result.interaction.action} on element \`${result.interaction.selector}${actionInputStr}\``)
          await makeWebPageInteraction(result.interaction)
            .then(changes => {
              if (result.interaction.description) {
                addStatusMessage(result.interaction.description, true);
              }
            })
            .catch(setError);
        }
        if (result.jsonData) {
          addMessage({
            __typename: 'TMessage',
            role: RoleV2.Assistant,
            content: '```\n' + JSON.stringify(result.jsonData, null, 2) + '\n```'
          });
        }
      }
      if (result.__typename == 'TUserInputRequirement') setInputRequirement(result);
      if (result.__typename == 'TAppDebugInfo') {
        addDebugLog(result);
      }
      if (result.__typename == 'TAppStatusUpdate') {
        setCompleted(result.hasEnded);
        if (result.signinRequired) {
          onSigninRequired();
        }
      }
    }
    queueProcessingInProgress.current = false;
    setClientInProgress(false);
  }

  function trackSubscription(sub: Subscription) {
    // not doing anything to the prev subscription
    // as calling it will change inprogress state
    // we're relying on the UX to behave properly
    // so there'd never be overwriting of still running subscription
    // unsubscribe();
    setDisconnect(() => (keepSession?: boolean) => {
      sub.unsubscribe();
      // we have to reset state here instead of watching for subscription.closed
      // as there's no way to properly trigger when the closed value changes.
      setInitializing(false);
      setServerInProgress(false);
      if (!keepSession) {
        resetClient();
      }
    });
  }

  function addStatusMessage(message: string, persistent: boolean = false) {
    addMessage({ __typename: 'TStatusMessage', content: message, persistent });
  }


  function getObserver<T extends FetchResult>(
    getResult: (data: T) => TAppRunResult | TAppDebugRunResult | undefined,
    onComplete?: () => void,
  ): Observer<T> {
    return {
      next(value) {
        const res = getResult(value);
        if (res) {
          queue.current.push(res.result);
          setClientInProgress(true);
          processQueue();
        }
        if (value.errors) {
          setError(value.errors[0]);
        }
      },
      error(errorValue) {
        console.error('subscription error', errorValue);
        setError(errorValue);
        setServerInProgress(false);
        setInitializing(false);
        onComplete?.();
      },
      complete() {
        console.log('subscription complete');
        setServerInProgress(false);
        setInitializing(false);
        onComplete?.();
      },
    }
  }

  useEffect(() => {
    if (skip) return;

    setServerInProgress(true);
    if (clientId) {
      if (options.debug) {
        trackSubscription(
          client
            .subscribe({
              query: CONTINUE_DEBUG_RUN,
              variables: {
                siteId,
                clientId: clientId,
                userInputs: [],
                aiConfigJson: options.aiConfig,
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.acontinueDebugRun,
            ))
        );
      }
      else {
        trackSubscription(
          client
            .subscribe({
              query: CONTINUE_RUN,
              variables: {
                clientId: clientId,
                userInputs: [],
                replayMessages: true,
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.acontinueRun,
            ))
        );
      };
    }
    else {
      if (options.debug) {
        trackSubscription(
          client
            .subscribe({
              query: START_DEBUG_RUN,
              variables: {
                siteId,
                flow: options.flowConfig,
                flowId: options.flowId,
                startId: options.startId,
                userInputs: [],
                aiConfigJson: options.aiConfig,
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.astartDebugRun,
            ))
        );
      }
      else {
        trackSubscription(
          client
            .subscribe({
              query: START_RUN,
              variables: {
                flowId: options.flowId,
                startId: options.startId,
                userInputs: [],
              },
              fetchPolicy,
            })
            .subscribe(getObserver(
              v => v.data?.astartRun,
            ))
        );
      }
    }
  }, [options, skip]);

  const send = (userInputs: IUserInput[], onComplete?: () => void, replayMessages?: boolean) => {
    if (!clientId) return;
    setServerInProgress(true);
    if (options.debug) {
      trackSubscription(
        client
          .subscribe({
            query: CONTINUE_DEBUG_RUN,
            variables: {
              siteId,
              clientId,
              userInputs,
              aiConfigJson: options.aiConfig,
            },
            fetchPolicy,
          })
          .subscribe(getObserver(v => v.data?.acontinueDebugRun, onComplete))
      )
    }
    else {
      trackSubscription(
        client
          .subscribe({
            query: CONTINUE_RUN,
            variables: {
              clientId,
              userInputs,
              replayMessages,
            },
            fetchPolicy,
          })
          .subscribe(getObserver(v => v.data?.acontinueRun, onComplete))
      )
    }
  }

  return {
    clientId,
    initializing,
    inProgress,
    inputRequirement,
    messages,
    debugLogs,
    send,
    error,
    completed,
    disconnect,
  }
}

export function ChatApp(props: {
  flowId: string,
  // do not start the app run immediately if skip is true
  skip?: boolean,
}): React.ReactElement {
  const appRunOptions = useMemo<AppRunOptions>(() => ({
    debug: false,
    flowId: props.flowId,
    startId: null,
  }), [props.flowId]);
  const [showSignin, setShowSignin] = useState(false);
  const appRun = useAppRun(appRunOptions, () => setShowSignin(true), props.skip);

  return <>
    <ChatAppDialog {...appRun} />
    <SignInDialog
      open={showSignin}
      enableSignup={true}
      onSignin={() => {
        // NOTE: this could be wrong if signin required is not triggered at the beginning
        appRun.send([]);
        setShowSignin(false);
      }}
      message='Please signin first to use the app.'
    />
  </>
}

export function ChatAppDialog(props: AppRunHook): React.ReactElement {
  const [input, setInput] = useState<IUserInput>({});
  const [isStopped, setIsStopped] = useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [props.messages, props.inProgress, props.inputRequirement, props.completed]);


  return props.initializing
    ? <Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'><CircularProgress size='5rem' /></Box>
    // TODO error display is needed when the flow cannot be initialized
    : <Stack spacing={2} sx={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    }}>
      <Stack ref={containerRef} spacing={2} sx={{
        p: 2,
        flexGrow: 1,
        overflowY: 'auto',
        //TODO hack - hide convo when questionnaire is present
        //need to handle hide/show convo more generically and gracefully
        // display: inputRequirement.questionnaire && !waitingForServer && !chatEnded ? 'none' : 'inherit',
      }}>

        {props.messages.map(
          (msg, idx) => msg.__typename == 'TStatusMessage'
            ? <Box key={idx} maxWidth='100%' textOverflow='ellipsis' whiteSpace='pre-wrap' textAlign='center'>
              <Markdown>{msg.content}</Markdown>
            </Box>
            : <ChatBubble
              key={idx}
              message={msg}
            />
          // : <ChatCard key={idx} data={msgOrCard.card as TCard} />
        )}
        {props.inProgress ? <Box p={1}><Pulse><MoreHorizIcon color='disabled' /></Pulse></Box> : undefined}
        {props.error
          ? <Alert severity='error'>{props.error.name}: {props.error.message}</Alert>
          : undefined
        }
      </Stack>
      {props.completed
        ? <Box width='100%' p={1} display='flex' justifyContent='center' alignItems='center'>
          <Typography variant='subtitle1' color={theme.palette.text.secondary}>Conversation has ended.</Typography>
        </Box>
        : isStopped
          ? <Button variant='outlined' color='secondary' sx={{ borderRadius: '40px' }} onClick={() => {
            setIsStopped(false);
            props.send([], () => setInput({}));
          }}><Typography variant='h6'>Resume</Typography></ Button>
          : <ChatInput
            processing={props.inProgress}
            disabled={props.inProgress}
            value={input}
            inputRequirment={props.inputRequirement || {
              choicesOnly: false,
              choicesMultiple: false,
              knowledgeSelect: false,
              datasetSelect: false,
              fileSelect: false,
            }}
            onChange={setInput}
            onSubmit={s => props.send([s], () => setInput({}))}
            onCancel={() => {
              props.disconnect(true);
              setIsStopped(true);
            }}
          />
      }
    </Stack>
}
