import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { FlowNodeData, GraphNode } from '../types/GraphNode'
import { Connection, Edge, EdgeChange, NodeChange, OnConnect, XYPosition, applyEdgeChanges, applyNodeChanges } from 'reactflow'
import { ApolloClient, ApolloError, FetchResult } from '@apollo/client';
import { GET_DYNAMIC_PLUGIN_INFO, GET_FLOW_V2, GET_STATIC_INFO } from '../graphql/query'
import { createEdge, createFlowFromZustand, getGraphForZustand } from '../utils/graph-conversion';
import { SettingsMenuOption } from '../types/SettingsMenuOption'
import { CREATE_FLOW_V2, DELETE_FLOW_V2, UPDATE_FLOW_V2 } from '../graphql/mutation'
import { Selection, createEdges, createNodes } from '../utils/graph-conversion'
import { CreateFlowV2Mutation, DeleteFlowV2Mutation, TDynamicTypedPlugin, TFlowPluginType, TPluginConstructInfo, TPluginInfo } from '../../generated/gql/graphql';
import { AxiosError } from 'axios'
import { GraphQLError } from 'graphql'
import { arrayToObject } from '../utils/common';
import { useShallow } from 'zustand/react/shallow';
import { isEqual } from 'lodash';
import { removeTypename } from '../utils/removeTypename'
import { logDebug } from '../utils/logging';


interface ConnectHandle {
  nodeId: string,
  handleId: string
}

interface AppState {
  id: string,  // unsaved app will have empty string as id
  name: string,
  aiConfig: any,
  isPublic: boolean,
  startNodeId: string | null,
  signinRequired: boolean,
  // this is only used during connect. Handle can still be highlighted for other reasons other than being here.
  highlightedConnectHandles: ConnectHandle[],

  graph: {
    nodesData: { [id: string]: FlowNodeData },
    nodesLocations: { [id: string]: XYPosition },
    partialEdges: { [id: string]: Omit<Edge, 'style' | 'selected'> },
    selected: Selection | null,
  },

};

interface Actions {
  setSelectedSettingMenuOption: (option: SettingsMenuOption | null) => void,

  setAiConfig: (aiConfig: any) => void,
  setAppIsPublic: (isPublic: boolean) => void,
  setAppName: (name: string) => void,
  setStartNodeId: (startNodeId: string | null) => void,
  setSigninRequired: (requireSignin: boolean) => void,
  setHighlightedConnectHandles: (handles: ConnectHandle[]) => void,

  graph: {
    updateNodeData: (nodeId: string, updates: Partial<FlowNodeData>) => void,
    addNode: (n: GraphNode) => void,
    onNodesChange: (changes: NodeChange[]) => void,
    onEdgesChange: (changes: EdgeChange[]) => void,
    onConnect: OnConnect,
    removeNode: (nodeId: string) => void,
    removeSelected: () => void,
  }

  addErrorNotification: (error: AxiosError | ApolloError | GraphQLError | string) => void,
  addSuccessNotification: (message: string) => void,

  startDebug: () => void,
  endDebug: () => void,
  setTemplateView: (view: string | null) => void,
}

interface Graphql {
  loadApp: (client: ApolloClient<object>, appId: string | null) => Promise<void>,
  saveApp: (client: ApolloClient<object>, siteId: string) => Promise<FetchResult<string>>,
  deleteApp: (client: ApolloClient<object>) => Promise<FetchResult<DeleteFlowV2Mutation>>,
  duplicateApp: (client: ApolloClient<object>, siteId: string) => Promise<FetchResult<CreateFlowV2Mutation>>,

  loadStaticTypes: (client: ApolloClient<object>) => Promise<void>,
  loadDynamicType: (client: ApolloClient<object>, nodeId: string, constructParam?: TDynamicTypedPlugin) => Promise<void>,
  updateDefaultEditorView: (client: ApolloClient<object>, view: string) => Promise<void>,
}

interface Notification {
  time: Date,
  content: AxiosError | ApolloError | GraphQLError | {
    type: 'error' | 'success',
    message: string,
  },
}

interface SettingsMenu {
  selected: SettingsMenuOption | null,
}

interface EditorView {
  debugAppOpen: boolean,
  templateView: string | null,
}

interface TypeInfoCollection {
  // actual key is PluginType
  static: { [type: string]: TPluginInfo },
  construct: { [type: string]: TPluginConstructInfo },
  dynamic: {
    [appId: string]: {
      [nodeId: string]: TPluginInfo,
    }
  }
}

interface EditorState {
  settingsMenu: SettingsMenu,
  app: AppState,
  notifications: Notification[],
  types: TypeInfoCollection,

  editorView: EditorView,

  actions: Actions,
  graphql: Graphql,
}

const defaultApp: AppState = {
  id: '',
  name: 'Pixie app',
  aiConfig: null,
  isPublic: false,
  startNodeId: null,
  signinRequired: false,
  highlightedConnectHandles: [],
  graph: {
    nodesData: {},
    nodesLocations: {},
    partialEdges: {},
    selected: null,
  }
}

// Write the implementation here so it can be reused by multiple store methods
function removeNodeFromState(nodeId: string, state: EditorState) {
  delete state.app.graph.nodesData[nodeId];
  delete state.app.graph.nodesLocations[nodeId];
  if (state.app.graph.selected?.type === 'node' && state.app.graph.selected.id === nodeId) {
    state.app.graph.selected = null;
  }
  const edgeIds = Object.keys(state.app.graph.partialEdges);
  for (const edgeId of edgeIds) {
    const e = state.app.graph.partialEdges[edgeId]
    if (e.source === nodeId || e.target === nodeId) {
      removeEdgeFromState(edgeId, state);
    }
  }
  if (Object.keys(state.app.graph.nodesData).length === 0) {
    state.app.startNodeId = null;
  }
}

function removeEdgeFromState(edgeId: string, state: EditorState) {
  delete state.app.graph.partialEdges[edgeId];
  if (state.app.graph.selected?.type === 'edge' && state.app.graph.selected.id === edgeId) {
    state.app.graph.selected = null;
  }
}


export const useEditorStore = create<EditorState>()(
  devtools(subscribeWithSelector(immer((set, get) => ({
    settingsMenu: {
      selected: null,
    } as SettingsMenu,
    editorView: {
      debugAppOpen: false,
      templateView: null,
    } as EditorView,

    app: defaultApp,

    notifications: [] as Notification[],

    types: {
      static: {},
      construct: {},
      dynamic: {},
    } as TypeInfoCollection,

    actions: {
      setSelectedSettingMenuOption: (option: SettingsMenuOption | null) => set(state => {
        state.settingsMenu.selected = option;
      }),
      setAiConfig: (aiConfig: any) => set(state => {
        state.app.aiConfig = aiConfig;
      }),
      setAppIsPublic: (isPublic: boolean) => set(state => {
        state.app.isPublic = isPublic;
      }),
      setAppName: (name: string) => set(state => {
        state.app.name = name;
      }),
      setStartNodeId: (startNodeId: string | null) => set(state => {
        if (startNodeId === null || !(startNodeId in state.app.graph.nodesData)) {
          state.app.startNodeId = Object.keys(state.app.graph.nodesData)[0] || null;
        }
        else state.app.startNodeId = startNodeId;
      }),
      setSigninRequired: (requireSignin: boolean) => set(state => {
        state.app.signinRequired = requireSignin;
      }),
      setHighlightedConnectHandles: (handles: ConnectHandle[]) => set(state => {
        state.app.highlightedConnectHandles = handles;
      }),

      graph: {
        updateNodeData: (nodeId: string, updates: Partial<FlowNodeData>) => set(state => {
          const toUpdate = state.app.graph.nodesData[nodeId];
          if (toUpdate) {
            for (const [k, v] of Object.entries(updates)) {
              toUpdate[k] = v;
            }
          }
        }),
        addNode: (n: GraphNode) => set(state => {
          if (Object.keys(state.app.graph.nodesData).length === 0) {
            state.app.startNodeId = n.id;
          }
          state.app.graph.nodesData[n.id] = n.data;
          state.app.graph.nodesLocations[n.id] = n.position;
          // NOTE calling actions within set somehow doesn't work
          state.app.graph.selected = { type: 'node', id: n.id };
        }),
        onNodesChange: (changes: NodeChange[]) => set(state => {
          const nodes = createNodes(state.app.graph.nodesData, state.app.graph.nodesLocations, state.app.graph.selected);
          const newNodes = applyNodeChanges<FlowNodeData>(changes, nodes);
          for (const c of changes) {
            if (c.type === 'select' && c.selected === false && c.id === state.app.graph.selected?.id) {
              state.app.graph.selected = null;
            }
          }
          for (const node of newNodes) {
            state.app.graph.nodesData[node.id] = node.data;
            state.app.graph.nodesLocations[node.id] = node.position;
            if (node.selected) {
              state.app.graph.selected = { type: 'node', id: node.id };
            }
          }
        }),
        onEdgesChange: (changes: EdgeChange[]) => set(state => {
          const newEdges = applyEdgeChanges(changes, createEdges(state.app.graph.partialEdges, state.app.graph.selected));
          for (const c of changes) {
            if (c.type === 'select' && c.selected === false && c.id === state.app.graph.selected?.id) {
              state.app.graph.selected = null;
            }
          }
          for (const edge of newEdges) {
            if (edge.selected) {
              state.app.graph.selected = { type: 'edge', id: edge.id };
            }
            delete edge.selected;
            delete edge.style;
            state.app.graph.partialEdges[edge.id] = edge;
          }
        }),
        onConnect: (conn: Connection) => set(state => {
          if (conn.source && conn.target && conn.sourceHandle) {
            const newEdge = createEdge(conn.source, conn.target, conn.sourceHandle, {});
            state.app.graph.partialEdges[newEdge.id] = newEdge;
          }
        }),
        removeNode: (nodeId: string) => set(state => removeNodeFromState(nodeId, state)),

        removeSelected: () => set(state => {
          const selected = state.app.graph.selected;
          if (selected?.type === 'node') {
            removeNodeFromState(selected.id, state);
          }
          else if (selected?.type === 'edge') {
            removeEdgeFromState(selected.id, state);
          }
        }),
      },

      addErrorNotification: (error: AxiosError | ApolloError | GraphQLError | string) => {
        console.warn(error);
      },

      addSuccessNotification: (message: string) => {
        console.log(message);
      },

      startDebug: () => set(state => {
        state.editorView.debugAppOpen = true;
      }),
      endDebug: () => set(state => {
        state.editorView.debugAppOpen = false;
      }),
      setTemplateView: (view: string | null) => set(state => {
        state.editorView.templateView = view;
      }),
    },

    graphql: {
      loadApp: async (client: ApolloClient<object>, appId: string | null) => await loadApp(client, appId, get, set),

      saveApp: async (client: ApolloClient<object>, siteId: string) => {
        const state = get();
        const commonVars = {
          flowName: state.app.name,
          flow: createFlowFromZustand(
            Object.values(state.app.graph.nodesData),
            Object.values(state.app.graph.partialEdges),
          ),
          isPublic: state.app.isPublic,
          layout: Object.entries(state.app.graph.nodesLocations)
            .map(([id, loc]) => ({ id, x: loc.x, y: loc.y })),
          startNodeId: state.app.startNodeId,
          aiConfig: state.app.aiConfig,
          signinRequired: state.app.signinRequired,
        }
        if (!state.app.id) {
          const result = await client.mutate({
            mutation: CREATE_FLOW_V2,
            variables: {
              siteId,
              ...commonVars,
            }
          })
          return { ...result, data: result.data?.acreateFlowV2 } as FetchResult<string>;
        }
        else {
          const result = await client.mutate({
            mutation: UPDATE_FLOW_V2,
            variables: {
              flowId: state.app.id,
              ...commonVars,
            }
          })
          return { ...result, data: result.data?.aupdateFlowV2.id } as FetchResult<string>;
        }
      },

      deleteApp: async (client: ApolloClient<object>) => {
        const state = get();
        if (!state.app.id) {
          throw new Error("Cannot delete unsaved app");
        }
        const result = await client.mutate({
          mutation: DELETE_FLOW_V2,
          variables: { flowId: state.app.id }
        })
        return result;
      },

      duplicateApp: async (client: ApolloClient<object>, siteId: string) => {
        const state = get();
        if (!state.app.id) {
          throw new Error("Cannot duplicate unsaved app");
        }
        const result = await client.mutate({
          mutation: CREATE_FLOW_V2,
          variables: {
            siteId: siteId,
            flowName: state.app.name + ' (copy)',
            flow: createFlowFromZustand(
              Object.values(state.app.graph.nodesData),
              Object.values(state.app.graph.partialEdges),
            ),
            isPublic: state.app.isPublic,
            layout: Object.entries(state.app.graph.nodesLocations)
              .map(([id, loc]) => ({ id, x: loc.x, y: loc.y })),
            startNodeId: state.app.startNodeId,
            aiConfig: state.app.aiConfig,
            signinRequired: state.app.signinRequired,
          }
        })
        const newAppId = result.data?.acreateFlowV2;
        await loadApp(client, newAppId, get, set);
        return result;
      },

      updateDefaultEditorView: async (client: ApolloClient<object>, view: string) => {
        const state = get();
        if (state.app.id) {
          await client.mutate({
            mutation: UPDATE_FLOW_V2,
            variables: {
              flowId: state.app.id,
              defaultEditorView: view,
            }
          });
        }
      },

      loadStaticTypes: async (client: ApolloClient<object>) => {
        await client.query({ query: GET_STATIC_INFO })  // this is cached
          .then(res => {
            if (res.error) {
              get().actions.addErrorNotification(res.error);
            }
            else {
              set(state => {
                state.types.static = arrayToObject(res.data.alistPluginInfo, ['pluginType', 'static']);
                state.types.construct = arrayToObject(res.data.alistPluginConstructs, 'constructType');
              })
            }
          })
          .catch(get().actions.addErrorNotification);

      },

      loadDynamicType: async (client: ApolloClient<object>, nodeId: string, constructParam?: TDynamicTypedPlugin) => {
        logDebug(`loading dynamic type for ${nodeId}`)
        const state = get();
        const appId = state.app.id || ''; // we will store unsaved app's typeinfo under key ''
        let cparam = constructParam
          || state.app.graph.nodesData[nodeId]?.pluginType?.dynamic
          || state.types.dynamic[appId]?.[nodeId]?.pluginType?.dynamic;
        if (!cparam) {
          state.actions.addErrorNotification("Cannot load dynamic type without construct parameters.");
          return;
        }
        await client.query({
          query: GET_DYNAMIC_PLUGIN_INFO,
          variables: { config: removeTypename(cparam) },
          fetchPolicy: 'no-cache',
        }).then(res => {
          if (res.error) {
            get().actions.addErrorNotification(res.error);
          }
          else {
            set(state => {
              if (!(appId in state.types.dynamic)) {
                state.types.dynamic[appId] = {}
              }
              state.types.dynamic[appId][nodeId] = res.data.agetDynamicPluginInfo;
            })
          }
        })
          .catch(get().actions.addErrorNotification);
      },

    },
  }))))
)


export interface TypeInfo {
  type: TFlowPluginType,
  constructInfo: TPluginConstructInfo | undefined,
  pluginInfo: TPluginInfo | undefined,
  dynamicTypeInfoOutdated: boolean,
}

export const useTypeInfo = (): { [nodeId: string]: TypeInfo } => {
  const appId = useEditorStore(state => state.app.id);
  const nodeTypes = useEditorStore(
    state => Object.entries(state.app.graph.nodesData)
      .map(([id, data]) => ({ id: id, type: data.pluginType }))
  );
  const [
    staticTypes,
    constructTypes,
    dynamicTypes,
  ] = useEditorStore(useShallow(state => [
    state.types.static,
    state.types.construct,
    // dynamic type info for unsaved app saved under empty app key
    state.types.dynamic[appId || ''] || {},
  ]))

  const ret = nodeTypes.reduce((ret, { id, type }) => {
    ret[id] = {
      type: type,
      constructInfo: type.dynamic?.constructType && constructTypes[type.dynamic.constructType],
      pluginInfo: type.static
        ? staticTypes[type.static]
        : dynamicTypes[id],
      dynamicTypeInfoOutdated: Boolean(type.dynamic && !isEqual(type.dynamic, dynamicTypes[id]?.pluginType?.dynamic)),
    };
    return ret;
  }, {} as { [nodeId: string]: TypeInfo })

  return ret;
}

export const useTypeInfoForNode = (nodeId: string): TypeInfo | null => {
  const appId = useEditorStore(state => state.app.id);
  const nodeType = useEditorStore(
    state => state.app.graph.nodesData[nodeId]?.pluginType as TFlowPluginType | undefined
  );
  const [
    staticInfo,
    constructInfo,
    dynamicInfo,
  ] = useEditorStore(useShallow(state => [
    nodeType?.static && state.types.static[nodeType.static],
    nodeType?.dynamic && state.types.construct[nodeType.dynamic.constructType],
    // dynamic type info for unsaved app saved under empty app key
    state.types.dynamic[appId || '']?.[nodeId] as TPluginInfo | undefined,
  ]))

  if (!nodeType) return null;

  return {
    type: nodeType,
    constructInfo: constructInfo || undefined,
    pluginInfo: staticInfo || dynamicInfo || undefined,
    dynamicTypeInfoOutdated: Boolean(nodeType.dynamic && !isEqual(
      removeTypename(nodeType.dynamic),
      removeTypename(dynamicInfo?.pluginType?.dynamic),
    ))
  };
}

function getRelatedNodeIds(fromNodeId: string | undefined, distance: number, edges: Omit<Edge, 'style' | 'selected'>[]): string[] {
  if (fromNodeId == undefined) return [];
  if (distance == 0) return [fromNodeId];
  const previousNodeIds = edges.filter(edge => edge.target === fromNodeId).map(edge => edge.source);
  return previousNodeIds.flatMap(nodeId => getRelatedNodeIds(nodeId, distance - 1, edges));
}

export function useRelatedNodeIds(distance: number | null): string[] {
  const relatedNodeIds = useEditorStore(state => {
    if (distance === null) return [];
    // TODO not very safe, we are assuming the rendering is always related to the selected node in graph
    // TODO it's better to have the fromNodeId passed in as a prop
    const selected = state.app.graph.selected;
    if (selected?.type != 'node') return [];
    return getRelatedNodeIds(selected.id, distance, Object.values(state.app.graph.partialEdges));
  });
  return relatedNodeIds;
}



useEditorStore.subscribe(state => state.types, logDebug)


///// helper functions //////////////////////////////////////
async function loadApp(client: ApolloClient<object>, appId: string | null, get: () => EditorState, set: (fn: (state: EditorState) => any) => void) {
  set(state => {
    state.settingsMenu.selected = null;
    state.editorView.debugAppOpen = false;
    state.app = defaultApp;
  })
  if (appId !== null) {
    const { data } = await client.query({
      query: GET_FLOW_V2,
      variables: { flowId: appId },
      fetchPolicy: 'no-cache',
    });
    const flow = data.agetFlowV2;
    const { nodesData, nodesLocations, edges } = getGraphForZustand(flow.config, flow.layout);
    set(state => {
      state.app.id = flow.id;
      state.app.aiConfig = flow.aiConfig;
      state.app.id = flow.id;
      state.app.name = flow.name;
      state.app.isPublic = flow.isPublic;
      state.app.graph.nodesData = nodesData;
      // have to use config since nodesData lost ordering
      state.app.startNodeId = flow.startNodeId || flow.config.plugins[0]?.id || null;
      state.app.graph.nodesLocations = nodesLocations;
      state.app.graph.partialEdges = edges;
      state.app.signinRequired = flow.signinRequired;
      state.editorView.templateView = flow.defaultEditorView;
    });
    const promises = flow.config.plugins
      .filter(p => p.pluginType.dynamic)
      .map(p => get().graphql.loadDynamicType(client, p.id, p.pluginType.dynamic || undefined))

    await Promise.all(promises);
  }
}
