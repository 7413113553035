import React, { useContext, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Stack, Dialog, Button } from '@mui/material';
import { AssetsContext } from '../../../contexts/AssetsContext';
import { DatasetEditor } from '../DatasetEditor';


export default function DatasetSelect(props: {
  value?: string,
  onChange: (datasetName: string | undefined) => void,
  label?: string,
}): React.ReactElement {
  const { datasets } = useContext(AssetsContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  // TODO properly get workspace for the upload
  const siteId = '218'

  return <Stack spacing={1} width='100%' p={2}>
    <FormControl>
      {props.label && <InputLabel id={props.label}>{props.label}</InputLabel>}
      <Select
        label={props.label}
        labelId={props.label}
        value={props.value || ''}
        onChange={e => {
          if (e.target.value) {
            props.onChange(e.target.value);
          }
          else {
            setDialogOpen(true);
          }
        }}
      >
        <MenuItem value=''>
          <Button variant='contained' sx={{ textTransform: 'none' }} onClick={() => setDialogOpen(true)}>Upload new dataset</Button>
        </MenuItem>
        {datasets.map(d => <MenuItem key={d} value={d}>{d}</MenuItem>)}
      </Select >
    </FormControl>
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth='lg'>
      <DatasetEditor siteId={siteId} datasetName={props.value} onSubmit={props.onChange} />
    </Dialog>
  </Stack>
}
