import React, { RefObject, useCallback, useEffect, useState } from 'react'
import { Box, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { TypeString } from './common';
import { JSONSchema7, JSONSchema7Definition } from 'json-schema';
import { AccessPathSegment, DataReferences, DynamicValue, DynamicValueParam, FormattedDynamicValue, isComposite, toExpression } from '../../types/DynamicValueTypes';
import { getSchema, typeToString } from './ParamEditor.utils';
import { getMatchingDefinitionIndex } from './ParamEditor.utils';
import { ParamInputField } from './ParamInputField';
import { getDynamicValue, getNonCompositeDynamicValue } from './ParamEditor.utils';
import { getValue, getUpdatedDynamicValue, getUpdatedValue } from './ParamEditor.utils';
import { MixedParamInputField } from './MixedParamInputField';
import { DataRefPopoverProps } from './DataRefPopover';
import { logDebug } from '../../utils/logging';
import { ArrayInputField } from './ArrayInputField';


function TypeSelect(props: {
  name: string,
  options: string[],
  // index of the selected type
  selected: number | 'dynamic',
  onChange: (selected: number | 'dynamic') => void,
  dynamicDisabled?: boolean,
  optional?: boolean,
}): React.ReactElement {
  const theme = useTheme();
  // TODO addItem button when onAddItem is present
  return <Stack>
    <Stack direction='row' spacing={1} display='flex' alignItems='baseline'>
      <Typography variant='subtitle1'><b>{props.optional ? '' : '*'}{props.name}</b></Typography>
      <Typography variant='body2' sx={{ color: theme.palette.text.secondary, pr: 1 }}>
        ({props.optional ? 'optional' : 'required'})
      </Typography>
    </Stack>
    <Box> {props.options.length == 1 && props.dynamicDisabled
      ? <Box pt={1} pb={1}><TypeString>{props.options[0]}</TypeString></Box>
      : <Select
        variant='standard'
        value={props.selected.toString()}
        // label='Data type'
        // labelId="dataType"
        color='primary'
        onChange={e => {
          let updated: number | 'dynamic' = e.target.value == 'dynamic' ? 'dynamic' : parseInt(e.target.value);
          if (updated == props.selected) return;
          props.onChange(updated);
        }}
        disableUnderline
      >
        {props.options.map(
          (op, idx) => <MenuItem key={idx} value={idx.toString()}>
            <TypeString>{op}</TypeString>
          </MenuItem>
        )}
        {!props.dynamicDisabled && <MenuItem value='dynamic'>
          <Typography variant='body2' p={1}>data reference</Typography>
        </MenuItem>}
      </Select>
    } </Box>
  </Stack>
}

// return the index
function getDefaultSelectedType(
  schema: JSONSchema7,
  value: any,
  dynamicValue?: DynamicValueParam
): number | 'dynamic' {
  if (dynamicValue && !isComposite(dynamicValue)) {
    return 'dynamic';
  }
  else {
    const idx = getMatchingDefinitionIndex(
      schema.anyOf || [],
      value,
    );
    return idx;
  }
}

// the key is the expression of the accessPath PRIOR to the type selection, while the value is the accessPath INCLUDING the type selection
// If the selected type is dynamic, the accessPath should not inlcude the type selection.
type TypeSelections = { [fieldName: string]: number | 'dynamic' };

// function getSelectedType(typeSelections: TypeSelections, accessPath: AccessPathSegment[]): number | 'dynamic' | undefined {
//   const key = toExpression({ reference: '', accessPath });
//   const fullAccessPath = typeSelections.get(key);
//   if (fullAccessPath && fullAccessPath.length > 0) {
//     const lastSeg = fullAccessPath[fullAccessPath.length - 1];
//     return lastSeg.type == 'typeSelect' ? lastSeg.value : 'dynamic';
//   }
//   return undefined;
// }

type Field = {
  schemaDef: JSONSchema7Definition,
  accessPath: AccessPathSegment[],
  optional?: boolean,
  default?: any,
}


function getFieldName(accessPath: AccessPathSegment[], includeTypeSelect?: boolean): string {
  return toExpression({ reference: '', access_path: accessPath }, includeTypeSelect);
}

function flattenFields(
  // currently schemaDef is scoped to the baseAccessPath
  // TODO to be consistent with other parameters this should be changed to always being root
  schemaDef: JSONSchema7Definition,
  baseAccessPath: AccessPathSegment[],
  // Value and dynamicValue is used to determine the default type selections
  // these should always be the root value/dv during recursive calls
  value: any,
  dynamicValue: DynamicValueParam | undefined,
  baseIsOptional?: boolean,
): { fields: Map<string, Field>, defaultTypeSelections: TypeSelections } {
  const ret = { fields: new Map<string, Field>(), defaultTypeSelections: {} };

  if (typeof schemaDef == 'boolean' || schemaDef.type == 'null') return ret;
  const fieldName = getFieldName(baseAccessPath, true); // important to include typeselect here

  ret.fields.set(fieldName, {
    schemaDef,
    accessPath: baseAccessPath,
    optional: baseIsOptional,
    default: getSchema(schemaDef)?.default,
  });

  // set default selection to dynamic if the dynamic value exists and is not composite
  const dynamicValueForPath = getDynamicValue(dynamicValue, baseAccessPath);
  if (dynamicValueForPath && !isComposite(dynamicValueForPath)) {
    ret.defaultTypeSelections[fieldName] = 'dynamic';
  }
  if (schemaDef.anyOf) {
    // whenever we popluate fields for anyOf type we need to set the defaultTypeSelection if it's not already set to dynamic
    ret.defaultTypeSelections[fieldName] = getDefaultSelectedType(
      schemaDef,
      getValue(value, baseAccessPath),
      getDynamicValue(dynamicValue, baseAccessPath),
    );
    schemaDef.anyOf.map(
      (subSchemaDef, idx) => flattenFields(
        subSchemaDef,
        [...baseAccessPath, { type: 'typeSelect', value: idx }],
        value,
        dynamicValue,
      )
    ).reduce(
      (ret, { fields: subFields, defaultTypeSelections: subTypeSelections }) => {
        for (const [k, f] of subFields.entries()) ret.fields.set(k, f);
        ret.defaultTypeSelections = { ...ret.defaultTypeSelections, ...subTypeSelections }
        return ret;
      },
      ret,
    )
  }
  else if (schemaDef.properties) {
    Object.entries(schemaDef.properties)
      .map((item, idx) => ({ item, idx }))
      .sort((a, b) => {
        const { item: [k1, schemaDef1], idx: idx1 } = a;
        const { item: [k2, schemaDef2], idx: idx2 } = b;
        const required1 = schemaDef.required?.includes(k1) || getSchema(schemaDef1)?.default !== undefined;
        const required2 = schemaDef.required?.includes(k2) || getSchema(schemaDef2)?.default !== undefined;
        if (required1 && !required2) return -1;
        else if (required2 && !required1) return 1;
        return idx1 - idx2;
      })
      .map(
        ({ item: [k, subSchemaDef] }) => flattenFields(
          subSchemaDef,
          [...baseAccessPath, { type: 'property', value: k }],
          value,
          dynamicValue,
          !(schemaDef.required?.includes(k) || getSchema(schemaDef)?.default !== undefined) // optional flag
        )
      ).reduce(
        (ret, { fields: subFields, defaultTypeSelections: subTypeSelections }) => {
          for (const [k, f] of subFields.entries()) ret.fields.set(k, f);
          ret.defaultTypeSelections = { ...ret.defaultTypeSelections, ...subTypeSelections }
          return ret;
        },
        ret,
      )
  }
  return ret;
}


function getTypeSelectOptions(schemaDef: JSONSchema7Definition): string[] {
  const schema = getSchema(schemaDef);
  if (schema?.anyOf) {
    return schema.anyOf.map(typeToString);
  }
  return [typeToString(schemaDef)];
}


function shouldShowField(field: Field, typeSelections: TypeSelections): boolean {
  for (let i = 0; i < field.accessPath.length; i++) {
    const currentSeg = field.accessPath[i];
    if (currentSeg.type == 'typeSelect') {
      // lookup typeselection of the parent segment for anyOf
      const parentFieldName = getFieldName(field.accessPath.slice(0, i), true);
      if (parentFieldName in typeSelections && typeSelections[parentFieldName] !== currentSeg.value) {
        return false;
      }
    }
  }
  return true;
}

function shouldShowTypeSelect(field: Field): boolean {
  if (field.accessPath.length == 0) return false; // do not show for root
  if (field.accessPath[field.accessPath.length - 1].type == 'typeSelect') return false; // do not show for selected anyOf
  return true;
}

function getDisplayName(field: Field): string {
  return toExpression({ reference: '', access_path: field.accessPath }).slice(1);
}

export function ParamEditor(props: {
  schema: JSONSchema7Definition,
  value: any,
  dynamicValue?: DynamicValueParam,
  // NOTE this has to be a single function instead of separate functions for value/dynamic value changes
  // because the way how update is implemented, separate value/dynamic value update calls would cause the first update to be discarded
  onChange: (value: any, dynamicValue: DynamicValueParam | undefined) => void,
  dynamicDisabled?: boolean,
  // a value that would trigger the editor to reset state upon change.
  resetTrigger: any,
  // container ref to show array item editor overlay, when editing an array item. default to this editor if not provided
  containerRef?: RefObject<HTMLElement>,
  // if provided, the parameditor component will be blacked out and overlayed by provided content
  disableOverlay?: React.ReactNode,
}): React.ReactElement {
  const [typeSelections, setTypeSelections] = useState<TypeSelections>({});
  const [focusedFieldName, setFocusedFieldName] = useState<string | null>(null);
  const updateTypeSelection = useCallback((fieldName: string, selected: number | 'dynamic') => {
    setTypeSelections(ts => ({ ...ts, [fieldName]: selected }))
    setFocusedFieldName(fieldName);
  }, []);

  const { fields, defaultTypeSelections } = React.useMemo(
    () => flattenFields(
      props.schema,
      [],
      props.value,
      props.dynamicValue,
    ),
    [props.schema, props.value, props.dynamicValue],
  );
  const typeSelectionsWithDefault = { ...defaultTypeSelections, ...typeSelections };

  useEffect(() => {
    setTypeSelections({});
  }, [props.resetTrigger]);

  return <Stack spacing={4} position="relative">
    {props.disableOverlay && <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="rgba(0, 0, 0, 0.7)" // Semi-transparent black background
      zIndex={1004}
      color='white'
    >
      {props.disableOverlay}
    </Box>
    }
    {Array.from(fields.entries()).map(
      ([fieldName, field], idx) => {
        const dvForField = getNonCompositeDynamicValue(props.dynamicValue, field.accessPath);
        const selectedType = typeSelectionsWithDefault[fieldName];
        const onFieldDvChange = (updated: DynamicValue | FormattedDynamicValue | null) => {
          props.onChange(
            props.value,
            getUpdatedDynamicValue(
              props.dynamicValue,
              updated,
              field.accessPath
            )
          );
        }

        return shouldShowField(field, typeSelectionsWithDefault) && <Stack spacing='2px' key={idx}>
          {/* Do not display typeSelect from the concrete field that's part of anyOf */}
          {shouldShowTypeSelect(field) && <TypeSelect
            name={getDisplayName(field)}
            selected={selectedType || 0}
            options={getTypeSelectOptions(field.schemaDef)}
            onChange={selected => updateTypeSelection(fieldName, selected)}
            dynamicDisabled={props.dynamicDisabled}
            optional={field.optional}
          />}
          {!props.dynamicDisabled && selectedType === 'dynamic'
            ? <MixedParamInputField
              initialFocus={focusedFieldName === fieldName}
              dynamicValue={dvForField}
              onChange={onFieldDvChange}
            />
            : <ParamInputField
              schema={getSchema(field.schemaDef)}
              value={getValue(props.value, field.accessPath)}
              onChange={value => props.onChange(getUpdatedValue(props.value, value, field.accessPath), props.dynamicValue)}
              dataRefProps={props.dynamicDisabled
                ? undefined
                : {
                  initialFocus: focusedFieldName === fieldName,
                  dynamicValue: null,
                  onChange(updatedDv) {
                    onFieldDvChange(updatedDv);
                    updateTypeSelection(fieldName, 'dynamic');
                  }
                }}
            />
          }
        </Stack>
      }
    )}
  </Stack>
}

export default ParamEditor;
