import { useMutation } from "@apollo/client/react";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UPLOAD_DOCUMENT, UPLOAD_IMAGE } from "../../graphql/mutation";
import { useDropzone } from "react-dropzone";
import { Box, Button, Card, Divider, LinearProgress, Stack, TextField, Typography, useTheme } from "@mui/material";
import { AppContext } from "../../contexts/AppContext";
import { useIdentityStore } from "../../hooks/IdentityState";

interface ImageMetadata {
  [fileName: string]: {
    file: File,
    temp_url: string,
    description: string,
  };
}

interface UploadStatus {
  [fileName: string]: number | false | null,
}


type FileWithDescription = File & {
  description?: string,
}


export function Uploader(props: {
  onChange: (files: string[]) => void,
  onSubmit: (fileUploadStatuses: UploadStatus) => void,
}): React.ReactElement {
  const siteId = useIdentityStore(state => state.workspaceId);
  const { setError, setSuccessMessage } = useContext(AppContext);
  const theme = useTheme();

  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const [documents, setDocuments] = useState<FileWithDescription[]>([]);
  const [images, setImages] = useState<ImageMetadata>({});
  const uploadStatus = useRef<UploadStatus>({});
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  function isImage(file: File) {
    return file.type.startsWith('image/');
  }

  const onDrop = useCallback((files: File[]) => {
    setDocuments(files.filter(f => !isImage(f)));
    const imgs = files.filter(isImage).reduce(
      (d, item) => {
        d[item.name] = {
          temp_url: URL.createObjectURL(item),
          file: item,
          description: "",
        };
        return d;
      },
      {}
    );
    setImages(imgs);
  }, [])

  // cleanup previous temp urls on setImages
  useEffect(() => {
    return () => {
      for (const key in images) {
        URL.revokeObjectURL(images[key].temp_url);
      }
    };
  }, [images]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function upload() {
    setUploadInProgress(true);

    uploadStatus.current = [
      ...documents.map(f => f.name),
      ...Object.keys(images),
    ].reduce(
      (obj, fileName) => {
        obj[fileName] = null;
        return obj;
      },
      {},
    );
    if (Object.keys(uploadStatus.current).length == 0) {
      setUploadInProgress(false);
      setUploadCompleted(true);
      return;
    }

    documents.forEach((file) => {
      uploadDocument({ variables: { siteId, file } })
        .then(res => {
          if (res.errors) setError(res.errors[0]);
          if (res.data) uploadStatus.current[file.name] = parseInt(res.data.auploadDocument);
        })
        .catch(e => setError(JSON.stringify(e)))
        .finally(() => {
          if (uploadStatus.current[file.name] === null) uploadStatus.current[file.name] = false;
          // TODO handle upload failure
          setUploadCompleted(Object.values(uploadStatus.current).every(v => v));
        })
    });
    Object.keys(images).forEach(fileName => {
      uploadImage({
        variables: {
          siteId,
          file: images[fileName].file,
          description: images[fileName].description,
        }
      })
        .then(res => {
          if (res.errors) setError(res.errors[0]);
          if (res.data) uploadStatus.current[fileName] = res.data.auploadImage;
        })
        .catch(e => setError(JSON.stringify(e)))
        .finally(() => {
          if (uploadStatus.current[fileName] === null) uploadStatus.current[fileName] = false;
          // TODO handle upload failure
          setUploadCompleted(Object.values(uploadStatus.current).every(v => v));
        })
    })
  }

  useEffect(() => {
    // TODO handle upload failure
    if (uploadCompleted) props.onSubmit(uploadStatus.current);
    if (uploadInProgress) setUploadInProgress(false);
  }, [uploadCompleted]);

  return (
    <Card sx={{ p: 4 }}>
      <Stack spacing={2}>
        {uploadInProgress
          ? <Typography variant="h4">Uploading...</Typography>
          : <Box {...getRootProps()} sx={{
            height: '200px',
            border: `2px dashed ${theme.palette.divider}`,
            p: 2,
            borderRadius: 2,
            cursor: 'pointer',
            userSelect: 'none',
          }}>
            <input {...getInputProps()} />
            <Typography sx={{ cursor: 'pointer' }}>{
              isDragActive ?
                'Drop the files here ...' :
                "Drag 'n' drop some files here, or click to select files"
            }</Typography>
          </Box>
        }
        <Stack spacing={1} pl={2} pr={2} divider={<Divider variant="middle" />}>
          {documents.map((f, idx) => {
            return <Stack key={`document-${idx}`} spacing={1}>
              <Typography variant="subtitle1"><b>{f.name}</b></Typography>
              <TextField
                multiline
                placeholder="Description"
                variant="standard"
                value={f.description || ''}
                onChange={e => {
                  documents[idx].description = e.target.value;
                  setDocuments([...documents]);
                }}
              />
            </Stack>;
          })}
        </Stack>

        {Object.keys(images).map((fileName, idx) => {
          return <Box key={`image-${idx}`}>
            <Stack spacing={2}>
              <Typography variant="h5">{fileName}</Typography>
              <Stack spacing={2} direction='row'>
                <img src={images[fileName].temp_url} alt="Preview" style={{ maxWidth: '50%', maxHeight: '300px', objectFit: 'contain' }} />
                <TextField
                  multiline
                  required
                  placeholder="Description"
                  variant="standard"
                  value={images[fileName].description}
                  // TODO is this even save not calling setState? setState do trigger temp url recycling though
                  onChange={e => {
                    images[fileName].description = e.target.value;
                    setImages({ ...images });
                  }}
                />
              </Stack>
            </Stack>
          </Box>;
        })}
        {uploadInProgress
          ? <LinearProgress />
          : <Button onClick={upload} variant="contained">Done</Button>
        }
      </Stack>
    </Card>

  );
}
