import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DynamicValue } from '../../types/DynamicValueTypes';
import { FlowNodeName } from '../../features/Pixie/Editor/FlowNodeName';
import { useRelatedNodeIds } from '../../hooks/EditorState';
import { parseDistance } from '../../types/DynamicValueTypes';


function RelativeFlowNodeName(props: {
  distance: number;
}): React.ReactElement {
  const relatedNodeIds = useRelatedNodeIds(props.distance);

  return <Stack direction='row' spacing={1} display='flex' flexWrap='wrap' divider={<p>|</p>}>
    {relatedNodeIds.map(nodeId => <FlowNodeName key={nodeId} nodeId={nodeId} variant='description' />)}
  </Stack>
}

export function DynamicValueDisplay(props: {
  dynamicValue: DynamicValue;
}): React.ReactElement {
  return <Stack
    direction='row'
    display='flex'
    alignItems='center'
    flexWrap='wrap'
  >
    {parseDistance(props.dynamicValue.reference) !== null
      ? <RelativeFlowNodeName distance={parseDistance(props.dynamicValue.reference)!} />
      : <FlowNodeName nodeId={props.dynamicValue.reference} variant='description' />
    }
    {props.dynamicValue.access_path.map(
      (seg, idx) => seg.type != 'typeSelect' && <Box key={idx} sx={{
        cursor: 'default',
        userSelect: 'none',
        // '&:hover': {
        //   textDecorationLine: 'underline',
        //   textDecorationColor: theme.palette.secondary.main,
        //   textDecorationThickness: 3,
        //   textUnderlineOffset: '4px',
        // }
      }}>
        <Typography p='1px' key={idx}>
          <b>{seg.type == 'property' ? `.${seg.value}` : `[${seg.value}]`}</b>
        </Typography>
      </Box>
    )}
  </Stack>;
}
